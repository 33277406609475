import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Card from '../clientStyles/Card.module.css'

function Booking(){


    return (
        <>
        <p>test test</p>
   
           
      </>
      
    )
}

export default Booking;