import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { useState,useEffect } from 'react';
import { googleLogout,useGoogleLogin } from '@react-oauth/google';

function Testmodal() {
  
 
  const [connectWithForm,useconnectWithForm] = useState(
    {
    fullname: "",
    email: "",
    password: "",
    phone: "",
    statusFlag: false
  }
  );


  const [connectWithGoogle,useconnectWithGoogle] = useState({
    fullname: "",
    email: "",
    password: "",
    statusFlag: false
  })

  //states poy tha staloyn stin laravel
   


  const { register, handleSubmit,formState: { errors } } = useForm()
  const onSubmit = (data) => {
    useconnectWithForm({
      ...connectWithForm,
      fullname: data.fullname,
      email:  data.email,
      password: data.password,
      phone: data.phone,
      statusFlag: true
    });
  };

   
  
  
 
  useEffect(() => {
    if (connectWithForm.statusFlag) {
      fetch('http://207.180.203.233/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          connectWithForm
        })
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log(data)
       
      })
      .catch(error => console.error(error));
    }
  }, [connectWithForm.statusFlag]);
  

//console.log(typeof connectWithForm)


  
  const [ user, setUser ] = useState([]);
  const [ profile, setProfile ] = useState([]);

  const login = useGoogleLogin({
      onSuccess: (codeResponse) => setUser(codeResponse),
      onError: (error) => console.log('Login Failed:', error)
  });
   
  

 
  useEffect(() => {
    fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
        Accept: 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => setProfile(data))
    .catch(error => console.error(error));
  }, [user]);
  

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
      googleLogout();
      setProfile(null);
  };





   


    return (
      <>
     <div className="bg">
     <div className="d-lg-flex half">
  <div
    className="bg order-1 order-md-2"
    style={{ backgroundImage: 'url("pp4-2.jpg")' }}
  />
  <div className="contents order-2 order-md-1">
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-md-7">
          <h3>
            Login to  <strong>justperfectym</strong>
          </h3>
          <p className="mb-4">
            place to book your sessions!
          </p>
           <form  method="post" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group first">
              <label htmlFor="username">Full Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="your full name"
                id="username"
                {...register("fullname", { required: "This is required.",maxLength: { value: 25, message: "Maximum length exceeded." }})}
                //onClick={(e) => e.preventDefault()}
                
              />
            </div>
            {errors.fullname && (
        <span className="text-danger">{errors.fullname.message}</span>
      )}
            <div className="form-group last">
              <label htmlFor="email">Email</label>
              <input
                type="Email"
                className="form-control"
                placeholder="Your Email"
                id="email"
                {...register("email", { required: "This is required.",maxLength: { value: 40, message: "Maximum length exceeded." },pattern: {
                  value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                  message: "Email address must be a valid address."
                }
            })}
            onInvalid={(e) => e.preventDefault()}
              />
            </div>
            {errors.email && (
        <span className="text-danger">{errors.email.message}</span>
      )}   
      <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <input
                type="tel"
                className="form-control"
                placeholder="Your Phone"
                id="phone"
                
               
                {...register("phone", { required: "This is required.",maxLength: { value: 10,  message: "At least 10 chars" }})}
                onChange={(e) => {
                  

                  if (!/^[0-9]*$/.test(e.target.value)) {
                    e.target.value = '';
                    
                  }
                  if (/[0-9]/.test(e.target.value) && e.target.value.length === 12) {
                    e.target.value = '';
                    
                  }

                  
                }}
              />
            </div>
            <div className="customstyleforpasserror">
            {errors.phone && (
              <span className="text-danger" >{errors.phone.message}</span>
            )}
            </div>
             
            <div className="form-group last mb-3">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Your Password"
                id="password"
                {...register("password", { required: "This is required.",minLength: { value: 8, message: "At least 8 chars" }})}
                
              />
            </div>
             
             

            <div className="customstyleforpasserror">
            {errors.password && (
              <span className="text-danger" >{errors.password.message}</span>
            )}
            </div>
            
            <input
              type="submit"
              value="Log In"
              className="btn btn-block"
              style={{backgroundColor: "black",color: "white"}}
            />
          </form>
          <hr className="hr-1" data-content="OR"/>
          
          <button onClick={login} className='login-with-google-btn'>Sign in with Google</button>
         
          <p>{profile.given_name}</p>
        </div>
      </div>
    </div>
  </div>
</div>

     </div>
     </>
    );
  }
  
  export default Testmodal;