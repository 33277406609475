import Testmodal from "./client/Testmodal";
import Booking from "./client/Booking";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

function App() {
  console.log(process.env)
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Testmodal />} />
        <Route path={process.env.PUBLIC_URL + '/Booking'} element={<Booking />} />
      </Routes>
    </Router>
  );
}

export default App;
